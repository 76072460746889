import Vue, {
  nextTick
} from 'vue'
import App from './App.vue'
import Cookies from 'js-cookie'
import actions from './store/index';
import router from './router'
import {
  registerMicroApps,
  start,
  setDefaultMountApp,
  prefetchApps
} from 'qiankun'
import microApps from './micro-app'
import './styles/common.scss'
// require('fundebug-revideo');
if (process.env.NODE_ENV !== 'development') {
  const fundebug = require("fundebug-javascript");
  fundebug.apikey = "26c66c8d67cb720e66a75da0fdc9d9afb45932df823b0f85a6968fb96eed7d63";
}
Vue.config.productionTip = false

///#region 删除旧的cookie
const opstimeDomain = '.opstime.xin'
const TokenKey = 'saber-access-token'
const RefreshTokenKey = 'saber-refresh-token'
const BkTokenKey = 'bk_token'
Cookies.remove(TokenKey, { domain: opstimeDomain })
Cookies.remove(BkTokenKey, { domain: opstimeDomain })
Cookies.remove(RefreshTokenKey, { domain: opstimeDomain })
///#endregion

// 定义loader方法，loading改变时，将变量赋值给App.vue的data中的isLoading
function loader(loading) {
  if (instance && instance.$children) {
    // instance.$children[0] 是App.vue，此时直接改动App.vue的isLoading
    instance.$children[0].isLoading = loading
  }
}

// 给子应用配置加上loader方法
const apps = microApps.map(item => {
  return {
    ...item,
    loader
  }
})
registerMicroApps(apps, {
  beforeLoad: app => {
    console.log('before load app.name====>>>>>', app.name)
    return Promise.resolve()
  },
  beforeMount: app => {
    console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name)
    return Promise.resolve()
  },
  afterMount: app => {
    const local = actions.link || ''
    if (app.name == 'project-micro' && local) {
      nextTick(() => {
        const head = document.getElementsByTagName('head')[0]
        const link = local.split(',')
        for (let i = 0; i < link.length; i++) {
          const p = link[i];
          let l = document.createElement('link');
          l.rel = 'stylesheet';
          l.href = p;
          head.appendChild(l)
        }
      })
    }
    return Promise.resolve()
  },

  afterUnmount: app => {
    console.log('[LifeCycle] after unmount %c%s', 'color: green;', JSON.stringify(app))
    const link = []
    const head = document.getElementsByTagName('head')[0]
    for (let i = 0; i < head.children.length; i++) {
      const element = head.children[i];
      if ((element.rel == 'stylesheet') && element.href.includes('/project-micro-app/')) {
        link.push(element)
      }
    }
    if (process.env.NODE_ENV !== 'development') {


      const urlLink = []
      for (let i = 0; i < link.length; i++) {
        const p = link[i];
        const url = p.href.split('/project-micro-app')
        urlLink.push(`/project-micro-app${url[1]}`)
        head.removeChild(p)
      }
      if (urlLink.length > 0) {
        actions.link = urlLink.join(',')
      }
    }
    console.log("🚀 ~ link:", link)
    return Promise.resolve()
  }
})
// addGlobalUncaughtErrorHandler((event) => {
//   const {
//     message: msg
//   } = event
//   if (msg && msg.includes('died in status LOADING_SOURCE_CODE')) {
//     console.error('加载失败')
//   }
// })

setDefaultMountApp('/saber-admin')
start()
Vue.prototype.$actions = actions
const instance = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
prefetchApps([{
    name: 'saber-admin',
    entry: process.env.NODE_ENV !== 'development' ? window.location.origin + '/saber-micro-app/' : '//localhost:8080',
  },
  {
    name: 'workflow-micro',
    entry: process.env.NODE_ENV !== 'development' ? window.location.origin + '/workflow-micro-app/' : '//localhost:8008'
  },
  {
    name: 'notify-micro',
    entry: process.env.NODE_ENV !== 'development' ? window.location.origin + '/notify-micro-app/' : '//localhost:8009'
  },
  {
    name: 'miniApp-micro',
    entry: process.env.NODE_ENV !== 'development' ? window.location.origin + '/miniApp-micro-app/' : '//localhost:8011'
  },
  {
    name: 'cmdb-micro',
    entry: process.env.NODE_ENV !== 'development' ? window.location.origin + '/cmdb-micro-app/' : '//localhost:8010'
  },
  {
    name: 'project-micro',
    entry: process.env.NODE_ENV !== 'development' ? window.location.origin + '/project-micro-app/' : '//localhost:8086'
  }
]);
// window.addEventListener('error', function (event) {
//   if (event.message.includes('LOADING_SOURCE_CODE')) {
//     localStorage.clear()
//     Cookies.remove('saber-access-token')
//     Cookies.remove('saber-refresh-token')
//     Cookies.remove('bk_token')
//     Vue.root.$router.push('/saber-admin#/login')
//   }
// })