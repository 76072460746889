import {
  initGlobalState
} from 'qiankun'
import Vue from 'vue'

// 父应用的初始state
// Vue.observable是为了让initialState变成可响应：https://cn.vuejs.org/v2/api/#Vue-observable。
const initialState = Vue.observable({
  /**执行方法名称 */
  fun: '',
  /**是否打开左侧菜单 */
  openFirstFun: 'close',
  /**函数参数 */
  args: [],
  /**执行文件名称 */
  executeFile: '',
  keyCollapse: false,
  isCollapse: false,
  isLoginPage: true,
  /** 左侧菜单 */
  leftMenu: [],
  /** 左侧菜单给谁 */
  lmMicro: '',
  /** 顶部菜单 */
  topMenu: [],
  tagvalue: '',
  /** 工作流路由 */
  workRouter: [],
  /** 当前标签 */
  addTag: null,
  /** 需要删除的标签 */
  delTag: null,
  /** Link标签 */
  link:[]
})

const actions = initGlobalState(initialState)

actions.onGlobalStateChange((newState, prev) => {
  // state: 变更后的状态; prev 变更前的状态
  console.log('main change', JSON.stringify(newState), JSON.stringify(prev))

  for (const key in newState) {
    initialState[key] = newState[key]
  }
})

// 定义一个获取state的方法下发到子应用
actions.getGlobalState = (key) => {
  // 有key，表示取globalState下的某个子级对象
  // 无key，表示取全部

  return key ? initialState[key] : initialState
}

export default actions