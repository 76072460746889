<template>
  <div style="background: #f0f2f5;">
    <template v-show="!isLoading">
      <div v-show="!isLoginPage" id="menu-viewport" :class="{ collapse: keyCollapse }"></div>
      <div class="main_right" :style="style">
        <div id="top-viewport" v-show="!isLoginPage"></div>
        <div id="subapp-viewport" :style="!isLoginPage ? 'overflow:auto;height:calc(100vh - 100px)' : ''"></div>
      </div>
    </template>
    <loading :loading="isLoading"/>
  </div>
</template>

<script>
import microApps from './micro-app'
import { loadMicroApp } from 'qiankun';
import store from './store'
import loading from './loading.vue'
export default {
  name: 'App',
  components: {
    loading
  },
  data() {
    return {
      isLoginPage: true,
      isLoading: true,
      keyCollapse: false,
      microApps,
      current: '/',
      microApp: null,
      microAppleft: null,
      isfristLoad: false
    }
  },
  mounted() {
    const _this = this
    this.listenRouterChange()
    store.onGlobalStateChange((state, pre) => {
      if (state.isLoginPage != pre.isLoginPage) {
        _this.isLoginPage = state.isLoginPage
        if (!state.isLoginPage) {
          if (!_this.isfristLoad) {
            _this.loadMicroAppLeftFun()
            _this.loadMicroAppFun()
          }
        } else {
          _this.isfristLoad = false
          _this.microApp.unmount()
          _this.microAppleft.unmount()
        }
      }
      if (state.keyCollapse != pre.keyCollapse) {
        _this.keyCollapse = state.keyCollapse
      }
    }, false)
  },
  computed: {
    style() {
      if (this.isLoginPage) {
        return 'width:100%'
      } else {
        return this.keyCollapse ? 'left:64px;width:calc(100% - 64px)' : 'left:220px;width:calc(100% - 220px)'
      }
    }
  },
  methods: {
    //#region 手动加载app
    loadMicroAppFun() {
      let origin = ''
      if (process.env.NODE_ENV !== 'development') {
        origin = window.location.origin
      }
      this.microApp = loadMicroApp(
        {
          name: 'top-menu-micro',
          entry: origin + process.env.VUE_APP_TOP_MENU_MICRO,
          container: '#top-viewport', // 子应用挂载的div
          activeRule: () => true,
          props: {
            getGlobalState: store.getGlobalState // 下发getGlobalState方法
          }
        },
      )
    },
    loadMicroAppLeftFun() {
      let origin = ''
      if (process.env.NODE_ENV !== 'development') {
        origin = window.location.origin
      }
      this.microAppleft = loadMicroApp({
        name: 'menu-micro',
        entry: origin + process.env.VUE_APP_MENU_MICRO,
        container: '#menu-viewport', // 子应用挂载的div
        activeRule: () => true,
        props: {
          getGlobalState: store.getGlobalState // 下发getGlobalState方法
        }
      },)
    },
    //#endregion
    goto(item) {
      history.pushState(null, item.activeRule, item.activeRule)
      this.current = item.name
    },
    bindCurrent() {
      const path = window.location.pathname
      if (this.microApps.findIndex(item => item.activeRule === path) >= 0) {
        this.current = path
      }
    },
    listenRouterChange() {
      const _wr = function (type) {
        const orig = history[type]
        return function () {
          const rv = orig.apply(this, arguments)
          const e = new Event(type)
          e.arguments = arguments
          window.dispatchEvent(e)
          return rv
        }
      }
      history.pushState = _wr('pushState')

      window.addEventListener('pushState', this.bindCurrent)
      window.addEventListener('popstate', this.bindCurrent)

      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('pushState', this.bindCurrent)
        window.removeEventListener('popstate', this.bindCurrent)
      })
    }
  },
  created() {
    this.bindCurrent()
    // NProgress.start()
  },
}
</script>
<style lang="scss" scoped>
.collapse {
  width: 64px !important;
}

.main_right {
  position: fixed;
  top: 0;
  height: 100%;
  transition: all 0.3s;
  overflow: auto;
  background: rgb(240, 242, 245);
}

#top-viewport {
  position: sticky;
  top: 0;
  transition: all 0.3s;
  z-index: 100;
}

#menu-viewport {
  width: 220px;
  transition: all 0.3s;
}
</style>
