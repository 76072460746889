<template>
  <div v-if="loading" class="loading-overlay">
    <div class="loading-content">
      <!-- <img src="./assets/loading.gif" alt=""> -->
      <div class="loading-icon" style="margin-right: 12px;"></div>加载中...
    </div>
  </div>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: true
    }
  },
}
</script>
<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure it's above other content */
}

.loading-content {
  color: white;
  display: flex;
  font-size: 18px;
  align-items: center;
  /* Add styling as needed */
}
.loading-icon {
  display: inline-block;
  font-size: 20px; /* 根据需要调整字体大小 */
  border: 4px solid rgba(255, 255, 255, 1); /* 透明边框 */
  border-top-color: #333; /* 加载动画颜色 */
  border-radius: 50%; /* 圆形加载图标 */
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}
 
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>